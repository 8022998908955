import { Controller } from 'stimulus'

import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Navigation, Autoplay)

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      spaceBetween: 10,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      autoplay: {
        delay: 8000,
      }
    })
  }
}
