import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'button']

  connect() {
    for (let button of this.buttonTargets) {
      if (button.dataset.product === this.inputTarget.value)
        button.classList.add('active')
    }
  }

  change(event) {
    const el = event.currentTarget

    this.inputTarget.value = el.dataset.product

    this.style(el)
  }

  style(el) {
    for (let button of this.buttonTargets) {
      button.classList.remove('active')
    }

    el.classList.add('active')
  }
}
