import { Controller } from 'stimulus'

import Swiper, { Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use(Pagination)

export default class extends Controller {
  static targets = ['pagination', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1.25,
      centeredSlides: true,
      spaceBetween: 0,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    })
  }
}
