import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper']
  static values = {
    products: Array,
  }

  connect() {
    this.extras = Object.fromEntries(this.productsValue)
  }

  change(event) {
    const el = event.currentTarget
    const title = el.dataset.extra

    this.extras[title] = Number(el.value)

    this.calculate()
  }

  calculate() {
    const extras = Object.fromEntries(
      Object.entries(this.extras).filter(([_, v]) => v != 0)
    )

    this.create(extras)
  }

  create(extras) {
    this.wrapperTarget.innerHTML = ''

    for (const [key, value] of Object.entries(extras)) {
      this.wrapperTarget.innerHTML += this.input(key, value)
    }
  }

  input(key, value) {
    return `<input type="textbox" name="booking[extras][]" value="[${key}, ${value}]">`
  }
}
