import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import RemoteController from 'stimulus-remote'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('remote', RemoteController)

import Flatpickr from 'stimulus-flatpickr'

require('flatpickr/dist/themes/dark.css')

application.register('flatpickr', Flatpickr)
